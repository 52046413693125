import { Box, Grid, Typography, useTheme } from '@mui/joy'
import Image from 'next/image'

export default function RentalsForOffer() {
  const theme = useTheme()

  return (
    <Grid
      container
      sx={{
        [theme.breakpoints.up('sm')]: {
          minHeight: 500,
        },
      }}
    >
      <Grid xs={0} sm={0} md={4}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        >
          <Image
            src="/assets/hero-left.png"
            alt="Hedgerow hero left"
            fill
            style={{
              objectFit: 'contain',
              objectPosition: 'left center',
            }}
          />
        </Box>
      </Grid>

      <Grid xs={12} sm={12} md={4}>
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            padding: 2,
            marginTop: 2,

            [theme.breakpoints.up('sm')]: {
              margin: 0,
            },
          }}
        >
          <Image src={'/assets/logo.png'} alt="logo" width={24} height={24} />
          <Box p={2} />
          <Typography level="h3">a selection of our</Typography>
          <Box p={1} />
          <Typography level="h1">RENTALS FOR OFFER</Typography>
          <Box p={1.5}>
            <hr style={{ width: 138, border: '0.5px solid #CCC6C6' }} />
          </Box>

          <Typography level="body1" letterSpacing="2px">
            <i>
              Presenting a collection of premier properties, now accepting
              offers to rent.
            </i>
          </Typography>

          <Box height={64} />
        </Box>
      </Grid>

      <Grid xs={0} sm={0} md={4} sx={{ position: 'relative' }}>
        <Image
          src="/assets/hero-right.png"
          alt="Hedgerow hero right"
          fill
          style={{ objectFit: 'contain', objectPosition: 'right center' }}
        />
      </Grid>
    </Grid>
  )
}
