import { Box, Container } from '@mui/joy'

import Listings from '../components/pages/landing/listings'
import RentalsForOffer from '../components/pages/landing/rentals-for-offer'
import Footer from '../components/shared/footer'
import Header from '../components/shared/header'
import listingsRepository from '../repos/listings-repository'
import { Listing } from '../types/listing'

export default function Landing({ listings }: { listings: Listing[] }) {
  return (
    <main>
      <Header />

      <RentalsForOffer />

      <Container>
        <Listings listings={listings} />
      </Container>

      <Box p={2} />

      <Footer />
    </main>
  )
}

export async function getStaticProps() {
  // TODO: expire cache
  const res = await listingsRepository().getAllListings()

  return {
    props: {
      listings: res,
    },
  }
}
