import Image from 'next/image'
import Link from 'next/link'
import { Box, Button, Grid, Typography, useTheme } from '@mui/joy'
import dayjs from 'dayjs'

import { Listing } from '../../../types/listing'
import AudreyHack from '../../shared/audrey-hack'
import { useEffect, useState } from 'react'

export default function Listings({ listings }: { listings: Listing[] }) {
  const theme = useTheme()

  const loadingObject = Object.fromEntries(
    listings.map(({ id }) => [id, false])
  )
  const [isLoading, setIsLoading] = useState(loadingObject)

  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload()
      }
    }
  }, [])

  return (
    <>
      {listings.map((listing, i) => (
        <Grid
          container
          key={`property-${listing.id}`}
          spacing={4}
          sx={{
            marginBottom: 7,
          }}
        >
          <Grid xs={12} sm={12} md={6}>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                minHeight: 272,

                [theme.breakpoints.up('sm')]: {
                  minHeight: 384,
                },
              }}
            >
              <Image
                src={listing.imageUrl}
                fill
                style={{ objectFit: 'cover', borderRadius: 0 }}
                alt={`Property ${i + 1}`}
              />
            </Box>
          </Grid>

          <Grid xs={12} sm={12} md={6}>
            <Box display="flex" flexDirection="column" height="100%">
              <Typography level="h2" gutterBottom>
                {listing.name}
              </Typography>

              <Typography level="body1" fontStyle="italic">
                {listing.description}
              </Typography>

              <Box p={2} />

              <Box flexGrow={1}>
                <Grid container spacing={4}>
                  <Grid xs={12} lg={6}>
                    <Typography level="body1">
                      <b>Bedrooms:</b> {listing.bedrooms}
                    </Typography>
                    <Box height={4} />
                    <Typography level="body1">
                      Bathrooms: {listing.bathrooms}
                    </Typography>
                    <Box height={4} />
                    <Typography level="body1">
                      Interior: {listing.interior} +/- SQ.FT.
                    </Typography>
                    <Box height={4} />
                    <Typography level="body1" gutterBottom>
                      Exterior: {listing.exterior} +/- ACRES
                    </Typography>
                  </Grid>
                  <Grid xs={12} lg={6}>
                    <Typography sx={{ flexGrow: 1 }} level="body1">
                      <b>Available Dates:</b>
                    </Typography>
                    <Box height={4} />
                    <Typography level="body1">
                      {dayjs(listing.startDate).format('MMM D YYYY')} -{' '}
                      {dayjs(listing.endDate).format('MMM D YYYY')}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Box height={16} />

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-around',
                  flexDirection: 'column',
                  gap: 2,

                  [theme.breakpoints.up('lg')]: {
                    flexDirection: 'row',
                    gap: 4,
                  },
                }}
              >
                <Button
                  size="lg"
                  component={Link}
                  fullWidth
                  loading={isLoading[listing.id]}
                  onClick={() =>
                    setIsLoading({ ...isLoading, [listing.id]: true })
                  }
                  href={`/listing/${listing.id}?cal=open`}
                >
                  <AudreyHack>Place Offer</AudreyHack>
                </Button>

                <Button
                  size="lg"
                  component={Link}
                  fullWidth
                  variant="outlined"
                  href={listing.readMoreUrl}
                >
                  <AudreyHack>Read More</AudreyHack>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ))}
    </>
  )
}
